'use strict';

import 'babel-polyfill';
import Slider from './components/slider';
import Player from './components/player';
import Gallery from './components/gallery';

window.script_popup = function script_popup(page, name, largeur, hauteur, options) {
    var top=(screen.height-hauteur)/2;
    var left=(screen.width-largeur)/2;
    window.open(page,name,"top="+top+",left="+left+",width="+largeur+",height="+hauteur+","+options);
}

const sliderContainers = [...document.querySelectorAll('.js-slider')];
sliderContainers.forEach(container => new Slider(container));

const galleryContainers = [...document.querySelectorAll('.js-gallery')];
galleryContainers.forEach(container => new Gallery(container));

new Player(document.querySelector('.js-player'));
