'use strict';

export default class Player {
    constructor(container) {
        this.container = container;
        this.audio = this.container.querySelector('audio');
        this.songs = [...this.container.querySelectorAll('.js-player-song')];
        this.currentSong = this.songs[0];

        this.initEvents();
    }

    initEvents() {
        this.songs.forEach(song => song.addEventListener('click', () => this.changeSong(song)));
        this.audio.addEventListener('ended', () => this.nextSong());
    }

    changeSong(song) {
        if (song === this.currentSong) {
            if (this.audio.paused) {
                this.audio.play();
            } else {
                this.audio.pause();
            }

            return;
        }

        song.focus();

        const songPath = song.getAttribute('data-song');

        this.audio.src = songPath;
        this.audio.play();

        this.currentSong.classList.remove('Player_song-playing');
        song.classList.add('Player_song-playing');

        this.currentSong = song;
    }

    nextSong() {
        const nextSongIndex = this.songs.indexOf(this.currentSong) + 1;

        if (nextSongIndex < this.songs.length) {
            this.changeSong(this.songs[nextSongIndex]);
        }
    }
}
