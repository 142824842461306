'use strict';

import PhotoSwipe from 'photoswipe';
import PhotoSwipeUiDefault from 'photoswipe/dist/photoswipe-ui-default';

export default class Gallery {
    constructor(container) {
        this.container = container;
        this.pswpElement = document.querySelector('.pswp');

        if (this.pswpElement == null || this.container == null) {
            return;
        }

        this.images = [...this.container.querySelectorAll('img')];
        this.photoSwipeItems = [];
        this.gallery = null;
        this.options = {
            bgOpacity: 0.85,
            closeOnScroll: false,
            history: false
        };

        this.initialize();
    }

    initialize() {
        this.images.forEach(image => {
            const item = {
                src: image.src,
                w: parseInt(image.getAttribute('data-width'), 10),
                h: parseInt(image.getAttribute('data-height'), 10)
            };

            this.photoSwipeItems.push(item);
        });

        console.log(this.photoSwipeItems);

        this.initEvents();
    }

    initEvents() {
        this.images.forEach((image, index) => image.addEventListener('click', () => this.show(index)));
    }

    show(index = 0) {
        this.options.index = index;
        this.gallery = new PhotoSwipe(this.pswpElement, PhotoSwipeUiDefault, this.photoSwipeItems, this.options);

        this.gallery.init();
    }
}
