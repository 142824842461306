'use strict';

export default class Slider {
    constructor(container, options = {}) {
        this.options = {
            controlButtonHiddenClass: 'Slider_control-hidden',
            autoInterval: 5000
        };

        Object.assign(this.options, options);

        this.container = container;
        this.slides = [...this.container.querySelectorAll('.js-slider-slide')];

        if (this.slides.length === 0) {
            return;
        }

        this.refSlide = this.slides[0];
        this.previousButton = this.container.querySelector('.js-slider-previous');
        this.nextButton = this.container.querySelector('.js-slider-next');

        this.index = 0;
        this.auto = this.container.getAttribute('data-slider-auto') !== null;
        this.nbSlides = this.slides.length;
        this.interval = null;

        if (this.auto && this.slides.length > 1) {
            this.play(true);
        }

        this.initControls();
        this.initEvents();
    }

    initControls() {
        if (this.nbSlides > 1) {
            this.nextButton.classList.remove(this.options.controlButtonHiddenClass);
        }
    }

    initEvents() {
        if (this.previousButton) {
            this.previousButton.addEventListener('click', () => this.previous());
        }

        if (this.nextButton) {
            this.nextButton.addEventListener('click', () => this.next());
        }

        this.container.addEventListener('mouseover', () => this.pause());
        this.container.addEventListener('mouseleave', () => this.play());
    }

    previous() {
        let index = this.index - 1;

        if (index < 0) {
            index = this.nbSlides - 1;
        }

        this.goTo(index);
    }

    next() {
        let index = this.index + 1;

        if (index >= this.nbSlides) {
            index = 0;
        }

        this.goTo(index);
    }

    goTo(index = this.index) {
        if (index < 0 || index >= this.nbSlides) {
            return;
        }

        this.index = index;

        this.setOffset();

        if (this.previousButton) {
            if (this.isOnFirstSlide()) {
                this.previousButton.classList.add(this.options.controlButtonHiddenClass);
            } else {
                this.previousButton.classList.remove(this.options.controlButtonHiddenClass);
            }
        }

        if (this.nextButton) {
            if (this.isOnLastSlide()) {
                this.nextButton.classList.add(this.options.controlButtonHiddenClass);
            } else {
                this.nextButton.classList.remove(this.options.controlButtonHiddenClass);
            }
        }
    }

    isOnFirstSlide() {
        return this.index === 0;
    }

    isOnLastSlide() {
        return this.index === this.nbSlides - 1;
    }

    setOffset() {
        this.refSlide.style.marginLeft = `${-this.index * 100}%`;
    }

    pause() {
        clearInterval(this.interval);
        this.interval = null;
    }

    play() {
        this.interval = setInterval(() => this.next(), this.options.autoInterval);
    }
}
